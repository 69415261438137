<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <div class="titleAction">
            <h1 class="systemTitle">Menus</h1>
            <router-link to="/business/addMenu"
              ><v-btn large>
                <v-icon class="mr-2"> mdi-plus </v-icon> Add Menu</v-btn
              ></router-link
            >
          </div>
          <v-btn class="mb-5" @click="sortMenu" v-if="!loading">
            Save Menu Position
          </v-btn>
          <v-btn class="mb-5" loading v-if="loading"></v-btn>
          <div class="rowBox">
            <td><strong> Menu Name</strong></td>
            <td><strong> Menu Link To</strong></td>
            <td><strong> Menu Position</strong></td>
            <td><strong> Action</strong></td>
          </div>

          <div class="rowBox" v-if="loading">
            <center class="py-3 px-3">
              Please wait system is sorting the menu
              <v-progress-circular
                class="ml-3"
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </center>
          </div>

          <draggable v-model="menus" v-if="!loading" class="dragBox">
            <div
              v-for="(data, index) in menus"
              draggable
              :key="index + 'product'"
              class="rowBox"
            >
              <td>{{ data.menuName }}</td>

              <td class="text-capitalize">{{ data.link }}</td>
              <td class="text-capitalize">{{ data.menuPosition }}</td>
              <td>
                <router-link :to="`/business/addMenu/${data._id}`"><v-icon> mdi-square-edit-outline </v-icon></router-link
                >
              </td>
            </div>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Menus",
  data: () => ({
    menus: [],
    loading: true,
  }),
  components: {
    draggable,
  },
  computed: {
    ...mapGetters(["allMenus"]),
  },
  methods: {
    ...mapActions(["getMenus", "postMenu"]),
    async sortMenu() {
      this.loading = true;
      let i = 0;
      for await (let ele of this.menus) {
        ele.index = i;
        await this.postMenu(ele);
        i++;
      }
      this.loading = false;
    },
  },
  async created() {
    await this.getMenus();
    this.menus = this.allMenus.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.rowBox {
  width: 100%;
  display: flex;

  td {
    border: 1px solid #efefef;
    padding: 10px !important;
    width: 33.33%;
  }
}
.dragBox {
  .sortable-chosen {
    cursor: grab;
  }
  .sortable-ghost {
    cursor: grabbing !important;
  }
}
</style>